// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// Use jwt service instead of default axios
import useJwt from '@src/auth/jwt/useJwt'

export const getAllData = createAsyncThunk('appCompanies/getAllData', async () => {  
  const response = await useJwt.getAllCompaniesData()
  return response.data
})

export const getData = createAsyncThunk('appCompanies/getData', async params => {
  const response = await useJwt.getCompanyData(params)
  return {
    params,
    data: response.data.companies,
    totalPages: response.data.total
  }
})

export const getCompany = createAsyncThunk('appCompanies/getCompany', async id => {
  const response = await useJwt.getCompany({ id })
  return response.data
})

export const addCompany = createAsyncThunk('appCompanies/addCompany', async (company, { dispatch, getState }) => {
  await useJwt.addCompany(company)  
  await dispatch(getData(getState().companies.params))
  await dispatch(getAllData())
  return company
})

export const updateCompany = createAsyncThunk('appCompanies/updateCompany', async (company, { dispatch, getState }) => {
  await useJwt.updateCompany(company)  
  await dispatch(getData(getState().companies.params))
  await dispatch(getAllData())
  return company
})

export const deleteCompany = createAsyncThunk('appCompanies/deleteCompany', async (id, { dispatch, getState }) => {
  await useJwt.deleteCompany({ id })
  await dispatch(getData(getState().companies.params))
  await dispatch(getAllData())
  return id
})

export const toggleLoading = createAsyncThunk('appCompanies/toggleLoading', async () => {
  return true
})


export const appCompaniesSlice = createSlice({
  name: 'appCompanies',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    selectedCompany: null,
    loading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.loading = false
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.selectedCompany = action.payload
        state.loading = false
      })
      .addCase(updateCompany.fulfilled, (state, action) => {
        state.selectedCompany = {...state.selectedCompany, ...action.payload }
      })
      .addCase(toggleLoading.fulfilled, (state) => {
        state.loading = true
      })
  }
})

export default appCompaniesSlice.reducer
