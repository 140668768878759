import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import apiService from '../../middleware/apiService.js'

const instance = apiService(axios)

export default class JwtService {
  // ** jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // ** Request Interceptor
    instance.interceptors.request.use(              
      config => {
        // ** Get token from localStorage
        let accessToken = this.getToken()

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          accessToken = JSON.parse(accessToken)
        
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    instance.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const { config, response } = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.accessToken)
              this.setRefreshToken(r.data.refreshToken)

              this.onAccessTokenFetched(r.data.accessToken)
            }).catch(() => {
              // ** Remove user, accessToken & refreshToken from localStorage
              localStorage.removeItem('userData')
              localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
              localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
              return Promise.reject(error)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(this.axios(originalRequest))
            })
          })
          return retryOriginalRequest
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return instance.post('/auth/signin', ...args)
    // return axios.post(this.jwtConfig.loginEndpoint, ...args)    
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    let refreshToken = this.getRefreshToken()
    if (refreshToken) {
      refreshToken = JSON.parse(refreshToken)

      return instance.post('/auth/refresh-token', { refreshToken })
    } else {
      return Promise.reject('No token found')
    }
    // return axios.post(this.jwtConfig.refreshEndpoint, {    
    //   refreshToken: this.getRefreshToken()
    // })
  }

  // User apis
  getAllUserData() {
    return instance.post('/users/get-all')
  }

  getUserData(...args) {
    return instance.post('/users/get-all', ...args)
  }

  getUser(...args) {
    return instance.post('/users/get-one', ...args)
  }

  addUser(...args) {
    return instance.post('/users/register', ...args)
  }

  deleteUser(...args) {
    return instance.post('/users/delete', ...args)
  }

  updateUser(...args) {
    return instance.post('/users/update', ...args)
  }

  userStatistics(...args) {
    return instance.post('/users/statistics', ...args)
  }

  changePassword(...args) {
    return instance.post('/users/change-password', ...args)
  }

  compareCompany(...args) {
    return instance.post('/users/compare-company', ...args)
  }

  // Company apis
  getAllCompaniesData() {
    return instance.post('/company/get-all')
  }

  getCompanyData(...args) {
    return instance.post('/company/get-all', ...args)
  }

  addCompany(...args) {
    return instance.post('/company/add', ...args)
  }

  deleteCompany(...args) {
    return instance.post('/company/delete', ...args)
  }

  getCompany(...args) {
    return instance.post('/company/get-one', ...args)
  }

  updateCompany(...args) {
    return instance.post('/company/update', ...args)
  }

  // Project apis
  getAllProjectsData() {
    return instance.post('/project/get-all')
  }

  getProjectData(...args) {
    return instance.post('/project/get-all', ...args)
  }

  addProject(...args) {
    return instance.post('/project/add', ...args)
  }

  deleteProject(...args) {
    return instance.post('/project/delete', ...args)
  }

  getProject(...args) {
    return instance.post('/project/get-one', ...args)
  }

  updateProject(...args) {
    return instance.post('/project/update', ...args)
  }

  // Report apis
  getAllReportsData() {
    return instance.post('/report/get-all')
  }

  getReportData(...args) {
    return instance.post('/report/get-all', ...args)
  }

  addReport(...args) {
    return instance.post('/report/add', ...args)
  }

  deleteReport(...args) {
    return instance.post('/report/delete', ...args)
  }

  getReport(...args) {
    return instance.post('/report/get-one', ...args)
  }

  updateReport(...args) {
    return instance.post('/report/update', ...args)
  }

  // Project sharing apis
  getUsersForNewProject(...args) {
    return instance.post('/project/get-all-user-for-new-project', ...args)
  }

  getUsersForExistingProject(...args) {
    return instance.post('/project/get-all-user-for-old-project', ...args)
  }

  getProjectsForClient(...args) {
    return instance.post('/project/get-projects-for-client', ...args)
  }

  // Country apis
  getAllCountries() {
    return instance.post('/country/get-all')
  }

  // File upload/download apis
  uploadFile(args) {
    return instance.post('/upload-file', args)
  }

  // powerbi account apis
  getAllPowerBIAccountData() {
    return instance.post('/powerbi-account/get-all')
  }

  getPowerBIAccountData(...args) {
    return instance.post('/powerbi-account/get-all', ...args)
  }

  addPowerBIAccount(...args) {
    return instance.post('/powerbi-account/add', ...args)
  }

  deletePowerBIAccount(...args) {
    return instance.post('/powerbi-account/delete', ...args)
  }

  getPowerBIAccount(...args) {
    return instance.post('/powerbi-account/get-one', ...args)
  }

  updatePowerBIAccount(...args) {
    return instance.post('/powerbi-account/update', ...args)
  }

  // pipeline apis
  getAllPipelinesData() {
    return instance.post('/pipeline/get-all')
  }

  getPipelineData(...args) {
    return instance.post('/pipeline/get-all', ...args)
  }

  addPipeline(...args) {
    return instance.post('/pipeline/add', ...args)
  }

  deletePipeline(...args) {
    return instance.post('/pipeline/delete', ...args)
  }

  getPipeline(...args) {
    return instance.post('/pipeline/get-one', ...args)
  }

  updatePipeline(...args) {
    return instance.post('/pipeline/update', ...args)
  }

  getPipelineCommonSettings(...args) {
    return instance.post('/pipeline/get-common-settings', ...args)
  }
  
  updatePipelineCommonSettings(...args) {
    return instance.post('/pipeline/update-common-settings', ...args)
  }

  // budget forecasting api
  getAllBudgetForecastingResources(...args) {
    return instance.post('/resource/budget-forecasting/get-all', ...args)
  }

  addBudgetForecastingResourceCategory(...args) {
    return instance.post('/resource/budget-forecasting/add-category', ...args)
  }

  updateBudgetForecastingResourceCategory(...args) {
    return instance.post('/resource/budget-forecasting/update-category', ...args)
  }

  deleteBudgetForecastingResourceCategory(...args) {
    return instance.post('/resource/budget-forecasting/delete-category', ...args)
  }

  addBudgetForecastingResourceItem(...args) {
    return instance.post('/resource/budget-forecasting/add-item', ...args)
  }

  updateBudgetForecastingResourceItem(...args) {
    return instance.post('/resource/budget-forecasting/update-item', ...args)
  }

  deleteBudgetForecastingResourceItem(...args) {
    return instance.post('/resource/budget-forecasting/delete-item', ...args)
  }

  // small business api
  getAllSmallBusinessResources(...args) {
    return instance.post('/resource/small-business/get-all', ...args)
  }

  addSmallBusinessResourceCategory(...args) {
    return instance.post('/resource/small-business/add-category', ...args)
  }

  updateSmallBusinessResourceCategory(...args) {
    return instance.post('/resource/small-business/update-category', ...args)
  }

  deleteSmallBusinessResourceCategory(...args) {
    return instance.post('/resource/small-business/delete-category', ...args)
  }

  addSmallBusinessResourceItem(...args) {
    return instance.post('/resource/small-business/add-item', ...args)
  }

  updateSmallBusinessResourceItem(...args) {
    return instance.post('/resource/small-business/update-item', ...args)
  }

  deleteSmallBusinessResourceItem(...args) {
    return instance.post('/resource/small-business/delete-item', ...args)
  }

  // general settings apis
  getGeneralSettings(...args) {
    return instance.post('/general-settings/get', ...args)
  }
  
  updateGeneralSettings(...args) {
    return instance.post('/general-settings/update', ...args)
  }

  // redshift query apis
  getReportFromRedshift(...args) {
    return instance.post('/redshift/query', ...args)
  }

  // calendar event apis
  // Company apis
  getAllCalendarEventData() {
    return instance.post('/calendar/get-all')
  }

  getCalendarEventData(...args) {
    return instance.post('/calendar/get-all', ...args)
  }

  addCalendarEvent(...args) {
    return instance.post('/calendar/add', ...args)
  }

  deleteCalendarEvent(...args) {
    return instance.post('/calendar/delete', ...args)
  }

  getCalendarEvent(...args) {
    return instance.post('/calendar/get-one', ...args)
  }

  updateCalendarEvent(...args) {
    return instance.post('/calendar/update', ...args)
  }  
}
